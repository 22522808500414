.mat-dialog-container {
  background-color: var(--table-bg);
  color: var(--text-color);
  padding: 2rem !important;
  max-height: 90vh !important;
  border-radius: 20px !important;
  border-top: 15px solid black;
}
.cdk-overlay-dark-backdrop {
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
}
.mat-dialog-title {
  font-weight: 700 !important;
}
.mat-dialog-content {
  max-height: initial !important;
  overflow: initial !important;
}
.mat-dialog-actions {
  margin-bottom: 0 !important;
  button {
    background-color: var(--button-dialog);
  }
}
