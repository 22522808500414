.mat-tab-label {
  padding: 0 !important;
}

.mat-tab-label-content {
  color: white;
}

.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #ffffff;
}
