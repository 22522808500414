@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.list-page {
  font-family: "Inter", sans-serif;
  width: 100%;
  min-height: calc(100% - (2rem + 20px));

  .title-page {
    font-size: 40px;
    color: var(--text-color);
    font-weight: 700;
  }

  .table-container {
    width: 100%;
    overflow: auto;
    padding: 0 15px 1rem;
    background-color: var(--table-bg);
    border-radius: 5px;

    .mat-table {
      background-color: var(--table-bg);
      border-radius: 5px;
      width: 100%;
      overflow: hidden;
      min-width: 1024px;
      border-spacing: 0 10px; /* Defina o valor do espaçamento vertical entre as linhas desejado */

      .column-state {
        .green-status {
          background-color: var(--green-color);
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 20px;
        }
        .blue-status {
          background-color: var(--cyan-color);
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 20px;
        }
        .yellow-status {
          background-color: var(--orange-color);
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 20px;
        }
        .red-status {
          background-color: var(--red-color);
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 20px;
        }
        .orange-status {
          background-color: var(--gray-color);
          display: block;
          width: 10px;
          height: 10px;
          border-radius: 20px;
        }
      }

      .column-active {
        min-width: max-content;

        .card-row {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        i {
          cursor: pointer;
          font-size: 16px;
          &:nth-child(1) {
            color: var(--red-color);
          }
          &:nth-child(2) {
            color: var(--blue-color);
          }
        }
      }

      .mat-no-data-row {
        td {
          text-align: center;
        }
      }
      tr {
        // ? Styling for <a> tags within a table --- (hyperlink)
        &:not(.mat-header-row) {
          position: relative;
          .hover-button {
            padding: 5px 10px;
            border-radius: 5px;
            transition: all 0.3s !important;
            color: var(--text-color) !important;
            text-decoration: underline !important;
            pointer-events: all !important;
            display: inline-block;
          }

          &:hover {
            .hover-button {
              transform: translateY(-3px);
              background-color: var(--text-color) !important;
              color: black !important;
            }
          }

          //? Estilização para quando a requisição nos retornar nenhum informação.

          .hover-no-content {
            color: var(--text-color);
            padding: 5px 10px;
            text-decoration: none;
            pointer-events: none;
          }
        }

        //? End of styling of the <a> tag --- (hyperlink)

        &:not(.mat-header-row) {
          background-color: var(--primary-bg);
          &:hover {
            background-color: var(--table-row-hover);
            transition: var(--ts);
          }
        }

        &.mat-header-row {
          height: 35px;
        }

        .mat-header-cell {
          font-size: 14px;
          color: var(--text-color);
          font-weight: 700;
        }

        .mat-cell {
          width: 21%;
          min-width: max-content;
          font-size: 14px;
          color: var(--text-color);
        }

        th.mat-header-cell {
          border-color: transparent;
        }

        td.mat-cell:first-of-type {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          padding-left: 20px;
        }

        td.mat-cell:last-of-type {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
  }
}
