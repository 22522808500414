@use "@angular/material" as mat;
@include mat.core();

$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
    density: 0,
  )
);
@include mat.all-component-themes($my-theme);

@import "styles/animations";
@import "styles/button";
@import "styles/dialog";
@import "styles/date-picker";
@import "styles/input";
@import "styles/loading-skeleton";
@import "styles/mat-panel";
@import "styles/media";
@import "styles/ngx-color";
@import "styles/scroll";
@import "styles/slider";
@import "styles/snackbar";
@import "styles/tab";
@import "styles/table";
@import "styles/transitions";
@import "styles/typography";
@import "styles/variables";

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  background: #0d0d0d;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  z-index: 10000000 !important;
}

.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

.cdk-overlay-connected-position-bounding-box {
  z-index: 99999999 !important;
}

.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  z-index: 99999999 !important;
}
