@import "./animations";

.card-input {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;

  label {
    font-size: 1rem;
    padding-left: 5px;
    color: var(--text-color);
  }

  .input-icon {
    position: relative;
    width: 100%;

    .mat-icon-button .mat-button-wrapper {
      color: var(--text-color);
    }
    input {
      width: 100%;
      padding-right: 2rem;
    }
    button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0px;
      color: var(--text-color-50);
      cursor: pointer;

      i {
        font-size: 1rem;
        color: var(--text-color);
      }
      &:disabled {
        opacity: 0.5;
      }
    }
  }

  input,
  textarea,
  select,
  .mat-select {
    padding: 12px 16px;
    border-radius: 5px;
    border: 1px solid var(--table-bg);
    background-color: var(--table-bg);
    outline: none;
    color: var(--text-color);
    box-sizing: border-box;

    .mat-select-arrow,
    .mat-select-value {
      color: var(--text-color);
    }
    .mat-select-placeholder {
      color: var(--text-color-50);
    }

    &[type="number"] {
      -webkit-appearance: textfield;
      -moz-appearance: textfield;
      appearance: textfield;
    }
    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
    &.ng-touched.ng-invalid {
      border-color: var(--red-color);
      @include shake;
    }
    &[type="file"] {
      display: none;
    }
    &::placeholder {
      color: var(--text-color-50);
    }
  }

  .mat-button {
    height: 41px;
  }

  & + .card-input {
    margin-top: 1rem;
  }
}

.mat-datepicker-toggle {
  color: var(--text-color);
}

.card-select {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    font-size: 1rem;
    padding-left: 5px;
    color: var(--text-color);
  }

  .mat-select {
    width: 100%;
    margin: auto;
  }

  .mat-select-arrow,
  .mat-select-value {
    color: var(--text-color);
  }

  .mat-select-placeholder {
    color: var(--text-color-50);
  }
}

.mat-form-field-infix {
  display: flex;
}
.mat-checkbox-frame {
  border-color: var(--text-color-50);
}

.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: var(--text-color);
}

.mat-checkbox-checkmark-path {
  stroke: var(--primary-bg) !important;
}

.mat-select-panel {
  background-color: var(--table-bg);
  &:hover {
    background-color: var(--table-row-hover);
  }
}

.mat-option-text {
  color: white;
  font-weight: 500;
}
