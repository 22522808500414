::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: var(--text-color);
  border-radius: 6px;
}

::-webkit-scrollbar-track {
  background: var(--table-bg);
  border-radius: 6px;
}

.mat-drawer-content::-webkit-scrollbar-track {
  border-radius: 0;
}
